// rememberno jQuery ;)

document.addEventListener("DOMContentLoaded", () => {


    var navTrigger = document.querySelector(".js-open-nav");
    var header = document.querySelector("nav");

    //toggle menu
    bindEvent(navTrigger, "click", function() {
        header.classList.toggle("js-is-open");
        this.classList.toggle("is-active");
    }, false);

    //click function
    function bindEvent(el, eventName, eventHandler) {
	    if (el.addEventListener){
	        el.addEventListener(eventName, eventHandler, false);
	    } else if (el.attachEvent){
	        el.attachEvent('on'+eventName, eventHandler);
	    }
	}

    var successMessage = document.getElementById('form-success');
    if(window.location.href.indexOf('?success') && successMessage){
        successMessage.scrollIntoView();
    }

});
